import React from "react";
import {Box, Button, Grid2, Typography} from "@mui/material";
import AboutEventSection from "../aboutEventSection/AboutEventSection";
import AboutSpeakersSection from "../aboutSpeakersSection/AboutSpeakersSection";
import './eventBody.css';

const EventBody = ({eventData}) => {
    const renderEventDetails = () => {
        return <Grid2 container mb={'48px'} sx={{
            flexDirection: {
                md: 'row',
                xs: 'column',
            },
            gap: {
                xl: '24px 80px',
                md: '20px 64px',
                xs: '12px',
            }
        }}>
            {eventData.eventmonth && <Grid2 className={'main-eventdetailsgrid'}>
                <Box>
                    <Typography className={'aboutevents'} sx={{
                        mb: '8px',
                        fontSize: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                        lineHeight: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                    }}>
                        {eventData.eventmonth}
                    </Typography>
                </Box>
            </Grid2>}
            {eventData.eventtime && <Grid2 className={'main-eventdetailsgrid'}>
                <Box>
                    <Typography className={'aboutevents'} sx={{
                        mb: '8px',
                        fontSize: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                        lineHeight: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                    }}>
                        {`${eventData.eventtime}${eventData.eventtype === 'Онлайн' ? ' МСК' : ''}`}
                    </Typography>
                </Box>
            </Grid2>}
            {eventData.duration && <Grid2 className={'main-eventdetailsgrid'}>
                <Box>
                    <Typography className={'aboutevents'} sx={{
                        mb: '8px',
                        fontSize: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                        lineHeight: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                    }}>
                        {eventData.duration}
                    </Typography>
                </Box>
            </Grid2>}
            {eventData.eventtype && <Grid2 className={'main-eventdetailsgrid'}>
                <Box>
                    <Typography className={'aboutevents'} sx={{
                        mb: '8px',
                        fontSize: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                        lineHeight: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                    }}>
                        {eventData.eventtype}
                    </Typography>
                </Box>
            </Grid2>}
            {eventData.location && <Grid2 className={'main-eventdetailsgrid'}>
                <Box>
                    <Typography className={'aboutevents'} sx={{
                        mb: '8px',
                        fontSize: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                        lineHeight: {
                            xl: '40px',
                            lg: '32px',
                            xs: '28px',
                        },
                    }}>
                        {eventData.location}
                    </Typography>
                </Box>
            </Grid2>}
        </Grid2>
    }
    const renderRegisterButton = () => {
        const  redirectToRegistration = () => {
            return window.open(`${eventData.link}`, '_blank');
        }
        return <Button variant={'contained'} onClick = {redirectToRegistration} sx={{
            p: '16px 40px',
            mt: {
                md: '48px',
                xs: '40px',
            },
            width: {
                md: 'auto',
                xs: '100%',
            },
            fontSize: '18px',
            lineHeight: '20px',
            backgroundImage: 'linear-gradient(to right, #0037ff, #0085ff)',
            color: 'white',
            borderColor: 'inherit',
            border: 'none',
            borderRadius: '8px',
            boxShadow: 'none',
        }}>
            {new Date(`${eventData.eventdate}:${eventData.formatendtime}`) >= new Date() ? 'Записаться' : 'Смотреть'}
        </Button>
    }

    return (
        <Box sx={{
            m: {
                xs: '0',
                lg: '0 auto',
            },
            p: {
                xs: '40px 16px',
                lg: '48px 0',
            },
            maxWidth: {
                xs: '100%',
                lg: '944px',
                xl: '1200px',
            },
        }}>
            {renderEventDetails()}
            {eventData.fulldescription ? <AboutEventSection eventData={eventData}/> : null}
            {eventData.speaker && !Object.keys(eventData.speaker).some(k => !k) ? <Box>
                <Typography className={'speakers'} variant={'h3'} mb={4}>
                    Спикеры
                </Typography>
                {Object.values(eventData.speaker).map((speaker, index) => {
                    return <AboutSpeakersSection key={index} speaker={speaker}/>
                })}
            </Box> : null}
            {eventData.link ? renderRegisterButton() : null}
        </Box>
    )
}

export default EventBody;