import {Box, Link, Typography, Container} from "@mui/material";
import './mainHeader.css';
import img from '../../assets/images/logowhite.svg';
import React from "react";

const MainHeader = () => {
    return (
        <Box className={'main-header'}>
            <Container disableGutters sx={{
                maxWidth: {
                    xs: '100%',
                    lg: '944px',
                    xl: '1200px',
                },
                p: {
                    xs: '24px 16px',
                    lg: '24px 0',
                },
                m: '0 auto',
                color: 'white',
                height: '100%',
            }}>
                <Link underline="hover" href="/">
                    <Box>
                        <img alt='' className={'img-class'} src={img}/>
                    </Box>
                </Link>
                <Typography className={'main-header__text-header'} sx={{
                    fontSize: {
                        xs: '40px',
                        lg: '50px',
                    },
                    mt: {
                        xs: '32px',
                        md: '64px',
                        xl: '124px',
                    },
                    mb: {
                        xs: '12px',
                        md: '20px',
                    },
                    lineHeight: {
                        xs: '40px',
                        lg: '50px',
                    },
                    width: {
                        xl: '50%',
                        md: '40%',
                        xs: '100%',
                    },
                    letterSpacing: '-3px',
                }}>
                    Афиша мероприятий
                </Typography>
                <Typography sx={{
                    fontSize: {
                        xs: '16px',
                        md: '18px',
                    },
                    lineHeight: {
                        xs: '20px',
                        md: '22px',
                    },
                    width: {
                        xl: '50%',
                        md: '40%',
                        xs: '100%',
                    },
                }}>
                    Уроки проходят в рамках проекта ЦБ РФ «Онлайн уроки финансовой грамотности»
                </Typography>
            </Container>
        </Box>
    )
}

export default MainHeader;