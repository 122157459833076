import {useEffect, useState} from "react";
import {Avatar, Box, Grid2, Typography} from "@mui/material";
import './aboutSpeakersSection.css';
import jsonService from "../../services/jsonService";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
const AboutSpeakersSection = ({speaker}) => {
    const [speakerImgUrl, setSpeakerImgUrl] = useState(null);

    useEffect(() => {
        async function func() {
            const imgUrl = await jsonService.getSpeakerImage(speaker.avatar);
            setSpeakerImgUrl(imgUrl)
        }
        func();
    }, []);

    return <Box sx={{
        p: {
            lg: '32px',
            xs: '24px'
        },
        display: 'flex',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 20px 0 rgba(21, 93, 241, 0.1)',
        '&:not(:last-child)': {
            marginBottom: '20px',
        },
    }}>
        <Avatar variant={'square'} sx={{
            width: 120,
            height: 120,
            borderRadius: '8px',
            mr: '32px',
            display: {
                md: 'block',
                xs: 'none',
            }
        }}>
            {speakerImgUrl ? <Box width={'120px'}
                                  height={'120px'}
                                  sx={{
                                      backgroundImage: `url(${speakerImgUrl})`,
                                      backgroundSize: 'cover'
                                  }}>

            </Box> : <AccountBoxIcon/>}
        </Avatar>
        <Grid2 sx={{

        }}>
            <Box mb={1} display={'flex'} alignItems={'center'}>
                <Avatar  variant={'square'} sx={{
                    width: 64,
                    height: 64,
                    borderRadius: '8px',
                    mr: '16px',
                    display: {
                        md: 'none',
                    }
                }}>
                    {speakerImgUrl ? <Box width={'64px'}
                                          height={'64px'}
                                          sx={{
                                              backgroundImage: `url(${speakerImgUrl})`,
                                              backgroundSize: 'cover'
                                          }}>

                    </Box> : <AccountBoxIcon/>}
                </Avatar>
                <Typography color={'#2F3441'} sx={{
                    fontSize: {
                        md: '32px',
                        xs: '16px'
                    },
                    lineHeight: {
                        md: '32px',
                        xs: '20px'
                    },
                }}>{speaker.name}</Typography>
            </Box>
            <Typography dangerouslySetInnerHTML = {{ __html: speaker.description }} sx={{
                fontSize: {
                    md: '18px',
                    xs: '16px'
                },
                lineHeight: {
                    md: '22px',
                    xs: '20px'
                },
                color: '#606981',
            }}></Typography>
        </Grid2>
    </Box>
}

export default AboutSpeakersSection;