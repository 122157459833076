import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {ruRU} from "@mui/x-date-pickers/locales";
import {DateCalendar} from "@mui/x-date-pickers/DateCalendar";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import {PickersDay} from "@mui/x-date-pickers";
import './customCalendar.css';
import {getDayAtStartOfDay, getMonthYearString} from "../../utils/dateUtils";

const CustomCalendar = ({events, selectedDay, setSelectedDay, setSelectedMonth}) => {
    dayjs.locale('ru');

    const sx = {
        '& .MuiBadge-badge': {
            // Adjustment for recordMade badge
            fontSize: '0.7em',
            paddingTop: '4px'
        },
        '& .MuiDayCalendar-header': {
            justifyContent: 'space-around',
            width: '100%',
            overflow: 'hidden',
        },
        '& .MuiPickersDay-root': {
            color: '#2F3441'
        },
        '& .MuiPickersArrowSwitcher-button': {
            padding: 0,
            width: '32px',
            height: '32px',
        },
        '& .MuiPickersArrowSwitcher-button .MuiSvgIcon-root': {
            width: '32px',
            height: '32px',
        },
        '& .MuiPickersArrowSwitcher-spacer': {
            width: '32px',
        },
        '& .MuiDayCalendar-weekContainer': {
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            margin: 0
        },
        '& .MuiDayCalendar-weekContainer:not(:last-child)': {
            marginBottom: '6px'
        },
        '& .MuiPickersDay-dayWithMargin': {
            // Grows width/height of day buttons
            width: '40px',
            height: '40px',
            fontSize: '18px',
        },
        '& .MuiBadge-root': {
            // Parent of button management
            aspectRatio: 1,
            width: '10%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center'
        },
        '& .MuiDayCalendar-weekDayLabel': {
            // Manages size of weekday labels
            fontSize: '18px',
            color: '#B6C1DD'
        },
        '& .MuiPickersCalendarHeader-root': {
            // Manages header root container
            margin: '0 0 12px',
            p: 0,
        },
        '& .MuiPickersCalendarHeader-label': {
            // Manages month/year size
            fontSize: '24px',
            lineHeight: '32px',
            color: '#2F3441',
            textTransform: 'capitalize'
        },
        '& .MuiPickersCalendarHeader-switchViewButton': {
            // Manages picker button
            display: 'none',
        },
        '& .MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer': {
            // Handles size of week row parent, 1.6 aspect is good for now
            overflow: 'hidden'
        },
        '& .MuiDayCalendar-slideTransition': {
            // Handles size of week row parent, 1.6 aspect is good for now
            width: '100%',
            overflow: 'hidden'
        },
        '& .MuiDayCalendar-loadingContainer': {
            width: '100%',
        },
        '& .MuiDayCalendarSkeleton-root': {
            width: '100%',
        },
        '& .MuiDayCalendarSkeleton-week': {
            width: '100%'
        },
        '& .MuiDayCalendarSkeleton-daySkeleton': {
            aspectRatio: '1 !important',
            height: 'auto !important'
        },
        '& .MuiPickersSlideTransition-root': {
            minHeight: "224px",
        },
        '& .MuiPickersArrowSwitcher-leftArrowIcon, & .MuiPickersArrowSwitcher-rightArrowIcon': {
            borderRadius: '50%',
            border: 'solid',
            borderWidth: '1px',
            padding: '3px',
            borderColor: '#0A2896',
            color: '#0A2896',
        },
        padding: {
            md: '40px',
            xs: '20px',
        },
        height: 'fit-content',
        maxHeight: 'none',
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '8px',
    }


    const highlightedDays = events.map(d => d.formatdate);

    /* Логика для выбора стиля дня в календаре в зависимости от типа дня */
    const renderCustomDay = (props) => {
        const {highlightedDays = [], day, outsideCurrentMonth, ...other} = props;

        const containsEvents =
            highlightedDays.includes(day.format("YYYY-MM-DD"));

        let pickersDay;

        if (selectedDay?.getTime() === new Date(day).getTime()) {
            pickersDay = getPickersDay('selected-day', other, outsideCurrentMonth, day );
        } else if (outsideCurrentMonth) {
            pickersDay = getPickersDay('outside-of-current-month', other, outsideCurrentMonth, day);
        } else if (containsEvents) {
            pickersDay = getPickersDay('has-events', other, outsideCurrentMonth, day);
        } else {
            pickersDay = getPickersDay('unclickable-day', other, outsideCurrentMonth, day);
        }

        return pickersDay;
    };

    const getPickersDay = (className, other, outsideCurrentMonth, day) => {
        return (
            <PickersDay
                className={className}
                {...other}
                outsideCurrentMonth={outsideCurrentMonth}
                day={day}
            />
        );
    }

    /* Если день в календаре не содержит мероприятий, то делаем его некликабельным */
    const setCorrectSelectedDay = (e) => {
        if (highlightedDays.includes(e.format("YYYY-MM-DD"))) {
            setSelectedDay(getDayAtStartOfDay(new Date(e)))
        }
    }

    return <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="ru"
        localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}>
        <DateCalendar
            onMonthChange={(e) => {
                setSelectedMonth(getMonthYearString(new Date(e)))
            }}
            showDaysOutsideCurrentMonth={true}
            onChange={setCorrectSelectedDay}
            slots={{
                day: renderCustomDay,
            }}
            slotProps={{day: {highlightedDays}}}
            sx={sx}
            dayOfWeekFormatter={(weekday) => `${weekday.format('dd')}`}
        />
    </LocalizationProvider>
}

export default CustomCalendar;