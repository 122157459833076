import {Box, Grid2} from "@mui/material";
import './calendarSection.css';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import CustomCalendar from "../customCalendar/CustomCalendar";
import {useEffect, useRef, useState} from "react";
import {getClosestDateWithEvents, getMonthYearString} from "../../utils/dateUtils";
import CalendarEvents from "../calendarEvent/CalendarEvents";
import NotFoundComponent from "../notFoundComponent/NotFoundComponent";

const CalendarSection = ({events, isMobile}) => {
    const [selectedMonth, setSelectedMonth] = useState(getMonthYearString(new Date()));
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [selectedDay, setSelectedDay] = useState(getClosestDateWithEvents(events, selectedMonth));
    const isInitialMount = useRef(true);

    dayjs.locale('ru');

    // Реагируем на изменения месяца, получаем новый файл, если он есть в списках
    useEffect( () => {
        const newSelectedDay = getClosestDateWithEvents(events, selectedMonth);
        setFilteredEvents(filterDataBySelectedDay(events, newSelectedDay));
        setSelectedDay(newSelectedDay);
    }, [selectedMonth]);

    // Реагируем на изменения даты в месяце, фильтруем мероприятия по выбранному дню
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setFilteredEvents(filterDataBySelectedDay(events, selectedDay));
        }
    }, [selectedDay]);

    // Фильтруем мероприятия по выбранному дню
    const filterDataBySelectedDay = (data, day) => {
        if (day === null) {
            return [];
        }
        const formattedSelectedDay = `${day.getFullYear()}-${String(day.getMonth() + 1).padStart(2, '0')}-${String(day.getDate()).padStart(2, '0')}`;
        return data.filter(event => event.formatdate === formattedSelectedDay);
    }

    return (
        <Box className={isMobile ? 'mobile_wrapper' : "calendar-wrapper"} mb={'80px'}>
            <Grid2 container width={'100%'} spacing={{
                md: '20px',
                xs: '40px'
            }}>
                <Grid2 size={{xs: 12, md: 6, xl: 4.8}}>
                    <CustomCalendar events={events} selectedDay={selectedDay} setSelectedDay={setSelectedDay} setSelectedMonth={setSelectedMonth} />
                </Grid2>
                <Grid2 size={{xs: 12, md: 6, xl: 7.2}}>
                    {filteredEvents.length > 0 ?
                        <CalendarEvents selectedDay={selectedDay} filteredData={filteredEvents}/> :
                        isMobile ?
                            null :
                            <NotFoundComponent
                                text={'Мероприятий в этом месяце не запланировано'}
                            />
                    }
                </Grid2>
            </Grid2>
        </Box>
    )
}

export default CalendarSection;