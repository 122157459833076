import {CardContent, Grid2, Typography} from "@mui/material";
import './calendarEventDetails.css';
import IconAndText, {ICON_TYPE} from "../IconAndText/IconAndText";

const CalendarEventDetails = ({name, time, location, typename}) => {
    return (
        <CardContent className={'hoverable'}
                     sx={{
                         p: '24px 40px',
                         backgroundColor: 'white',
                         borderRadius: '8px',
                         border: 'none',
                     }}>
            <Typography variant="h5" fontSize={{xl:'24px', xs: '20px'}} lineHeight={{xl: '28px', xs: '24px'}} marginBottom={'16px'} sx={{
                WebkitLineClamp: 2,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                color: '#2f3441',
            }}>
                {name}
            </Typography>
            <Grid2 container sx={{gap: '16px', alignItems: 'center'}}>
                <Grid2>
                    <IconAndText
                        icon={ICON_TYPE.time}
                        text={time}/>
                </Grid2>
                <Grid2>
                    <Typography sx={{fontSize: '18px', lineHeight: '22px', color: '#606981'}}>
                        {typename}
                    </Typography>
                </Grid2>
                <Grid2>
                    <IconAndText
                        icon={ICON_TYPE.location}
                        text={location}
                        wrapNormal/>
                </Grid2>
            </Grid2>
        </CardContent>
    )
}

export default CalendarEventDetails;