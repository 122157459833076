import {Box, Typography} from "@mui/material";
import "./aboutEventSection.css"

const AboutEventSection = ({eventData}) => {
    return <Box borderRadius={'8px'} sx ={{
        mb: '48px',
        p: {
            md: '40px',
            xs: '24px',
        },
        backgroundColor: 'white',
        boxShadow: '0 2px 20px 0 rgba(21, 93, 241, .1)'
    }}>
        <Typography sx={{
            mb: '20px',
            color: '#2F3441',
            fontSize: {
                md: '32px',
                xs: '24px',
            },
            lineHeight: {
                md: '32px',
                xs: '28px',
            },
        }}>Описание</Typography>
        <Typography sx={{
            m: 0,
            color: '#606981',
            fontSize: {
                md: '18px',
                xs: '16px',
            },
            overflow: 'auto',
        }} dangerouslySetInnerHTML = {{ __html: eventData.fulldescription }}></Typography>
    </Box>
}


export default AboutEventSection;