import React from 'react';
import {Box, Grid2, Typography} from "@mui/material";

const NotFoundComponent = ({text}) => {
    return (
        <Box>
            <Grid2 container>
                <Grid2 display={'flex'} justifyContent={'center'} textAlign={'center'}>
                    <Typography mb={{xs: '100px'}} color={'#b6c1dd'} fontSize={'32px'}>{text}</Typography>
                </Grid2>
            </Grid2>
        </Box>
    );
}

export default NotFoundComponent;