import api from "./api";

const jsonService = {

    getFiltersData: async () => {
        const filtersData = await api.getFiltersByUrl();
        try {
           return Object.values(JSON.parse(filtersData).filters)
        } catch (e) {
            console.log('Unable to parse json');
            return null;
        }
    },

    getEventsData: async (hash) => {
        const result = await api.getEventByUrl(hash);
        let eventObj = null;
        try{
            eventObj = JSON.parse(result);
        } catch(error){
            console.error('Unable to parse json')
            return {};
        }
        return eventObj;
    },

    // TODO отрефакторить после изменения бэка
    getEventData: async (relativePath) => {
        const month =  await api.getMonthByUrl(relativePath);
        return Object.values(JSON.parse(month));
    },

    // TODO отрефакторить после изменения бэка
    getAllEventsData: async (months) => {
        return await Promise.all(
            months.map(async (m) => {
                return await jsonService.getEventData(m);
            })
        );
    },

    getSpeakerImage: async (relativePath) => {
        return await api.getSpeakerImageByUrl(relativePath);
    },

    getAllSpeakerImagesMap: async (speakers) => {
        return await Promise.all(
            speakers.map(async (s) => {
                const imgUrl = await jsonService.getSpeakerImage(s.avatar);
                return {hash: s.hash, imgUrl: imgUrl, isLoading: false}
            })
        );
    },

    getListMonths: async () => {
        const listMonths = await api.getListMonthsByUrl();
        try {
           return Object.values(JSON.parse(listMonths))
        } catch (e) {
            console.log('Unable to parse json');
            return null;
        }
    },

}

export default jsonService;