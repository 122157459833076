import './App.css';
import {HashRouter as Router, Routes, Route} from "react-router-dom";

import {createTheme, useMediaQuery} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import EventsPage from "./components/pages/EventsPage";
import EventPage from "./components/pages/EventPage";
import {createContext, useState} from "react";
export const Context = createContext();

function App() {
    const isMobile = useMediaQuery('(max-width:767px)');
    const isTablet = useMediaQuery('(min-width:768px) and (max-width:1600px)');
    const isDesktop = !isTablet && !isMobile;

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 375,
                md: 768,
                lg: 1024,
                xl: 1600,
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
            },
        },
        typography: {
            fontFamily: '"VTBGroupUI", sans-serif',
        }
    });

    const [dimensions, setDimensions] = useState({isMobile, isTablet, isDesktop});

    return (
        <Context.Provider value={{dimensions, setDimensions}}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Routes>
                        <Route path="/" element={<EventsPage />} />
                        <Route path="/event/:hash" element={<EventPage />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        </Context.Provider>
    );
}

export default App;
