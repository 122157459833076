import './mainBody.css';
import {useEffect, useRef, useState} from "react";
import CalendarSection from "../calendarSection/CalendarSection";
import EventsSection from "../eventsSection/EventsSection";
import FilterSection from "../filterSection/FilterSection";
import {Box, CircularProgress, Grid2, Typography, useMediaQuery} from "@mui/material";
import Grid from '@mui/material/Grid2';
import CustomButtonGroup, {PRESSED_BUTTON} from "../customButtonGroup/CustomButtonGroup";
import jsonService from "../../services/jsonService";

const MainBody = () => {
    const [events, setEvents] = useState([]);
    const [filters, setFilters] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [eventsLists, setEventsLists] = useState([]);
    const [eventsLoading, setEventsLoading] = useState(true);
    const [pressedButton, setPressedButton] = useState(PRESSED_BUTTON.AFTER);

    // TODO отрефакторить после изменения бэка
    const fetchEvents = async (monthArray) => {
        const rawData = await jsonService.getAllEventsData(monthArray) ?? [];
        return rawData.flat();
    }

    const fetchFilters = async () => {
        return await jsonService.getFiltersData();
    }

    const fetchListMonths = async () => {
        return await jsonService.getListMonths();
    }

    const initialFetchData = async () => {
        const listWithEvents = await fetchListMonths();
        const eventsData = await fetchEvents(listWithEvents.map(list => list.filename));
        const filtersData = await fetchFilters();

        return { eventsData, filtersData, listWithEvents };
    }
    
    /* Инициализация состояния */
    useEffect( () => {
        const fetchAndSetData = async () => {
            const { eventsData, filtersData, listWithEvents } = await initialFetchData();

            setEvents(eventsData);
            setFilters(filtersData);
            setEventsLists(listWithEvents);
        }
        fetchAndSetData();
    }, []);

    // Для скрытия лоадера после загрузки данных
    useEffect(() => {
        const isLoading = events.length === 0 && filters.length === 0;
        setEventsLoading(isLoading);
    }, [events, filters]);

    const isMobile = useMediaQuery('(max-width:767px)');
    const isTablet = useMediaQuery('(min-width:768px) and (max-width:900px)');
    const isMacAir = useMediaQuery('(min-width:901px) and (max-width:1599px)');

    return (eventsLoading ?  <Box sx={{
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            height: "100%",
            flexGrow: 1,
        }}>
            <CircularProgress />
        </Box>
        :
        <Box sx={{
            m: {
                xs: '0',
                lg: '0 auto',
            },
            p: {
              xs: '64px 16px',
              lg: '80px 0',
            },
            maxWidth: {
                xs: '100%',
                lg: '944px',
                xl: '1200px',
            },
            width: '100%',
            height: '100%',
            flexGrow: 1,
        }}>
            <CalendarSection
                isMobile={isMobile}
                events={events}
            />

            <Box>
                <Grid2 sx={{
                    mb: '40px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}>
                    <Typography variant={'h3'}
                                sx={{
                                    color: "#B6C1DD",
                                    fontSize: {
                                        xs: '40px',
                                        md: '50px'
                                    },
                                    lineHeight: {
                                        xs: '40px',
                                        md: '50px'
                                    },
                                    letterSpacing: '-3px'
                                }}>
                        Список мероприятий
                    </Typography>

                    <Box>
                         {(isTablet || isMacAir) && <CustomButtonGroup
                            setPressedButtonInGroup={setPressedButton}
                            pressedButtonInGroup={pressedButton}
                            buttonName1={'Предстоящие'} buttonName2={'Прошедшие'}/>}
                    </Box>
                </Grid2>

                <Grid container spacing={{xs: 2.5}}>
                    <Grid2 size={{xl: 3.15, xs: 12}} sx={{
                        position: 'sticky',
                        backgroundColor: "#f3f7fa",
                        zIndex: 2,
                        top: {
                            xl: '20px',
                            xs: '0'
                        },
                    }}>
                        <FilterSection
                            filters={filters}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            isMacAir={isMacAir}
                            pressedButton={pressedButton}
                            setPressedButton={setPressedButton}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                        />
                    </Grid2>
                    <Grid2 size={{xl: 8.85, xs: 12}}>
                        <EventsSection
                            selectedFilters={selectedFilters}
                            events={events}
                            pressedButton={pressedButton}
                            isMobile={isMobile}
                        />
                    </Grid2>
                </Grid>
            </Box>
        </Box>
    )
}

export default MainBody;