import {Box, Button, Dialog, DialogContent, DialogTitle, Grid2, Typography} from "@mui/material";
import './mobileFilter.css';
import img from '../../assets/images/filter.svg'
import {useState} from "react";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {FilterItem} from "../filterItem/FilterItem";

const MobileFilter = ({filters, selectedFilters, setSelectedFilters}) => {

    const [filterModalIsOpen, setFilterModalIsOpen] = useState(false)

    const getSelectedFiltersNumber = () => {
        let counter = 0;
        Object.keys(selectedFilters).forEach(k => {
            if (selectedFilters[k] !== -1) {
                counter++;
            }
        })
        return counter;
    }

    const buttonStyle = {
        backgroundColor: 'inherit',
        color: '#0A2896',
        borderColor: 'inherit',
        border: '1px',
        borderRadius: '8px',
        boxShadow: '0 0 0 1px gray inset',
        padding: '14px 24px',
        width: "100%",
        textTransform: 'none',
        fontSize: '18px',
    };

    let renderFilterDialog = () => {
        return <Dialog
            fullScreen
            open={filterModalIsOpen}
        >
            <DialogTitle
                sx={{backgroundColor: '#f3f7fa'}}
            >
                <Grid2 container>
                    <Grid2 size={2} display={'flex'} alignItems={'center'}>
                        <ArrowBackIosNewRoundedIcon
                            onClick={()=>setFilterModalIsOpen(!filterModalIsOpen)}
                        />
                    </Grid2>
                    <Grid2 size={8} display={'flex'} justifyContent={'center'}>
                        <Typography fontSize={'24px'}>
                            Фильтры
                        </Typography>
                    </Grid2>
                    <Grid2 size={2}>
                    </Grid2>
                </Grid2>
            </DialogTitle>
            <DialogContent
                sx={{backgroundColor: '#f3f7fa'}}
            >
                <Grid2 container sx={{flexDirection: 'column', gap: '12px'}}>
                    {filters.map(filter => {
                        return <FilterItem
                            key={filter.id}
                            filter={filter}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                        />
                    })}
                </Grid2>
            </DialogContent>
        </Dialog>
    }

    return (
        <Grid2 container size={12}>
            <Button style={buttonStyle}
                    onClick={() => setFilterModalIsOpen(!filterModalIsOpen)}
            >
                <Grid2 size={6} sx={{display: 'flex'}}>
                    <img
                        alt=''
                        className={'img-filter'}
                        src={img}/>
                    Фильтры
                </Grid2>
                <Grid2 size={6} sx={{display: 'flex', justifyContent: 'end'}}>
                    <Box sx={{
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: "50%",
                        width: '25px',
                        height: '25px',
                        backgroundColor: '#0A2896',
                        color: 'white',
                    }}>
                        <Box>
                            {getSelectedFiltersNumber()}
                        </Box>
                    </Box>
                </Grid2>
            </Button>
            {renderFilterDialog()}
        </Grid2>
    )
}

export default MobileFilter;