import {Avatar, Box, Button, CardContent, Chip, Grid2, Typography} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import React, {Fragment} from "react";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import './eventsDetails.css';
import IconAndText, {ICON_TYPE} from "../IconAndText/IconAndText";

const EventsDetails = ({data, isMobile}) => {
    const navigate = useNavigate();

    const renderDateSection = () => {
        return <Fragment>
            <Typography variant={'h1'}>
                <Box className={'date-primary'} sx={{
                    fontSize: '80px',
                    lineHeight: '80px',
                    fontWeight: '500',
                }}>
                    {data.date}
                </Box>
            </Typography>
            <Typography variant={'h5'} mb={'32px'}>
                <Box className={'month-name-primary'} sx={{
                    color: '#2f3441',
                    fontSize: '24px',
                    lineHeight: '24px'
                }}>
                    {data.month}
                </Box>
            </Typography>
            <Box sx={{width: 'fit-content', marginInline: 'auto', textAlign: 'center'}}>
                <IconAndText
                    icon={ICON_TYPE.time}
                    text={data.time}/>
                {data.chip === 'Онлайн' ? <Typography sx={{
                    fontSize: '18px',
                    color: '#606981',
                    lineHeight: '22px',
                }}>МСК</Typography> : null}
            </Box>
        </Fragment>
    }

    const renderMobileDateSection = () => {
        return <Fragment>
            <Grid2 container sx={{alignItems: 'center', flexWrap: 'nowrap'}}>
                <Typography className={'date-primary'} sx={{
                    mr: '12px',
                    fontSize: '64px',
                    lineHeight: '64px',
                    fontWeight: '500',
                    '&:not(:last-child)': {
                        mb: 0,
                    },
                }}>
                    {data.date}
                </Typography>
                <Grid2 container sx={{
                    flexDirection: 'column',
                }}>
                    <Typography variant={'h5'}>
                        <Grid2 className={'month-name-primary'} sx={{
                            mb: '4px',
                            color: '#2f3441',
                            fontSize: '20px',
                            lineHeight: '20px',
                        }}>
                            {data.month}
                        </Grid2>
                    </Typography>
                    <Grid2>
                        <IconAndText sx={{display: 'flex', alignItems: 'center'}}
                                     icon={ICON_TYPE.time}
                                     text={`${data.time}${data.chip === 'Онлайн' ? ' МСК' : ''}`}/>
                        {data.cityName ? <Box sx={{mt: '4px'}}>
                            <IconAndText
                                icon={ICON_TYPE.location}
                                wrapNormal={true}
                                text={data.cityName}/>
                        </Box> : null}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Fragment>
    }

    const renderButtonsBlock = (data) => {
        const redirectToRegistration = () => {
            return window.open(`${data.link}`, '_blank');
        }

        const handleButtonClick = () => {
            navigate(`/event/${data.hash}`);
        }
        return <Box sx={{display: 'flex', gap: '16px', mt: 'auto'}}>
            <Button
                variant={'outlined'}
                className={`event-card-general-button  
                ${isMobile ? 'event-card-general-button-xs-left' : 'event-card-general-button-xl-left'}`}
                onClick={handleButtonClick}
                sx={{
                    width: isMobile ? '100%' : 'auto',
                    p: isMobile ? '16px' : '16px 24px',
                }}
            >
                Подробнее
            </Button>
            <Button
                variant={'contained'}
                className={`event-card-general-button-left 
                ${isMobile ? 'event-card-general-button-xs' : 'event-card-general-button-xl'}`}
                onClick = {redirectToRegistration}
                sx={{
                    width: isMobile ? '100%' : 'auto',
                    p: isMobile ? '16px' : '16px 24px',
                }}
            >
                {new Date(`${data.formatdate}:${data.formatendtime}`) >= new Date()  ? 'Записаться' : 'Смотреть'}
            </Button>
        </Box>
    }
    const renderCardText = () => {
        return <Fragment>
            <Typography className={'event-card-title-text'} sx={{
                fontSize: {
                    md: '32px',
                    xs: '24px',
                },
                lineHeight: {
                    md: '36px',
                    xs: '28px',
                },
                mb: {
                    md: '12px',
                    xs: '8px',
                },
                color: '#2f3441'
            }}>
                {data.name}
            </Typography>
            <Typography variant={'h6'} sx={{mb: {md: '20px'}}}>
                <Box className={'event-card-description-text'} sx={{
                    color: '#606981'
                }}>
                    {data.description}
                </Box>
            </Typography>
        </Fragment>
    }

    const renderImageAndText = () => {
        if (!data.speakerExists) {
            return null
        }
        return <Box>
            <Avatar  variant={'square'}
                     sx={{width: 80, height: 80, borderRadius: '8px', mb: '12px'}}
            >
                {data.image ? <Box width={80}
                                   height={80}
                                   sx={{
                                       backgroundImage: `url(${data?.image})`,
                                       backgroundSize: 'cover'
                                   }}>

                </Box> : <AccountBoxIcon/>}
            </Avatar>

            <Typography variant={'h5'}>
                <Box sx={{fontSize: '18px', lineHeight: '22px', fontWeight: 500, color: '#2f3441', mb: '8px'}}>{data.speakerName}</Box>
            </Typography>
            <Typography sx={{fontSize: '16px', lineHeight: '20px', fontWeight: 300, color: '#2f3441'}}
                 className={'speaker-position-text'}
                 dangerouslySetInnerHTML={{__html: data.speakerPosition}}
            >
            </Typography>
        </Box>
    }
    const renderMobileImageAndText = () => {
        if (!data.speakerExists) {
            return null
        }
        return <Fragment>
            <Grid2 sx={{display: 'flex', mb: '16px'}}>
                <Grid2 sx={{minWidth: 'auto'}}>
                    <Avatar variant={'square'}
                            sx={{width: 64, height: 64, borderRadius: '8px', mr: '12px'}}
                    >
                        {data.image ? <Box width={64}
                                           height={64}
                                           sx={{
                                               backgroundImage: `url(${data?.image})`,
                                               backgroundSize: 'cover'
                                           }}>

                        </Box> : <AccountBoxIcon/>}
                    </Avatar>
                </Grid2>
                <Grid2 container sx={{flexDirection: 'column'}}>
                    <Typography variant={'h5'}><Box
                        sx={{fontSize: '16px', lineHeight: '20px', fontWeight: 500, color: '#2f3441', mb: '4px'}}>{data.speakerName}</Box></Typography>
                    <Typography sx={{fontSize: '14px', lineHeight: '18px', fontWeight: 300, color: '#2f3441'}}
                         dangerouslySetInnerHTML={{__html: data.speakerPosition}}
                         className={'speaker-position-text'}>
                    </Typography>
                </Grid2>
            </Grid2>
        </Fragment>
    }

    return (
        <CardContent className={'hoverable card-content'} sx={{
            padding: 0,
            position: 'relative',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 20px 0 rgba(21, 93, 241, .05)',
            cursor: 'unset',
            '&:last-child': {
                paddingBottom: 0
            },
            '@media (max-width: 600px)': {
                '&::before, &::after': {
                    display: 'none',
                }
            }
        }}>
            <Chip label={data.chip} className={'custom-chip'} sx={{
                paddingInline: '16px',
                height: '24px',
                color: 'white',
                borderTopLeftRadius: '70px',
                borderTopRightRadius: '40px',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: '40px',
            }}/>
            <Grid2 container rowSpacing='0' gap='0'>
                <Grid2 size={{xl: 2.35, md: 2.2, xs: 12}} sx={{
                    p: '20px',
                    borderRight: {
                        md: `1px solid #f3f7fa`
                    }
                }}>
                    {isMobile ? renderMobileDateSection() : renderDateSection()}
                </Grid2>
                <Grid2 size={{xl: 6.5, md: 6.7, xs: 12}} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: {
                        md: '32px 20px',
                        xs: '0 20px',
                    },
                    borderRight: {
                        md: `1px solid #f3f7fa`
                    }
                }}>
                    {renderCardText()}
                    {!isMobile ? data.cityName ? <Box sx={{mb: '16px'}}>
                        <IconAndText
                            icon={ICON_TYPE.location}
                            wrapNormal={true}
                            text={data.cityName}/>
                    </Box> : null : null}
                    {!isMobile ? renderButtonsBlock(data) : null}
                </Grid2>
                <Grid2 size={{xl: 3.15, md: 3.1, xs: 12}} sx={{
                    p: {
                        md: '32px 20px',
                        xs: '20px',
                    }
                }}>
                    {isMobile ? renderMobileImageAndText() : renderImageAndText()}
                    {isMobile ? renderButtonsBlock(data) : null}
                </Grid2>
            </Grid2>
        </CardContent>
    )
}

export default EventsDetails;