import {Box, Grid2, Typography} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import './IconAndText.css';

export const ICON_TYPE = {
    time: <AccessTimeIcon sx={{color: '#606981', height: '20px'}}/>,
    location: <FmdGoodOutlinedIcon sx={{color: '#606981', top:'1px', height: '20px'}}/>
}

const IconAndText = ({icon, text, wrapNormal}) => {
    if (!text) {
        return null;
    }

    return <Grid2 sx={{display: 'flex', alignItems: 'center', color: '#606981'}}>
        <Box className={'icon-img'} sx={{display: 'flex', alignItems: 'center', mr: '6px', color: '#606981'}}>
            {icon}
        </Box>
        <Grid2>
            <Typography component={'span'}>
                <Box className={'icon-text'} sx={{
                    fontSize: '18px',
                    color: '#606981',
                    whiteSpace: wrapNormal ? 'normal' : 'nowrap',
                    overflow: 'visible',
                    lineHeight: '22px',
                    wordBreak: 'break-word',
                }}>{text}</Box>
            </Typography>
        </Grid2>
    </Grid2>
}

export default IconAndText;