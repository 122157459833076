import {Grid2} from "@mui/material";
import './filterSection.css';
import CustomButtonGroup from "../customButtonGroup/CustomButtonGroup";
import MobileFilter from "../mobileFilter/MobileFilter";
import {FilterItem} from "../filterItem/FilterItem";

const FilterSection = ({filters, isTablet, pressedButton, setPressedButton, selectedFilters, setSelectedFilters, isMacAir, isMobile}) => {
    return (
        <Grid2 container spacing={(isTablet || isMacAir) ? 1.5 : 2.5} pb={'20px'}>
            {(!isTablet && !isMacAir) &&
                <Grid2 size={{xl: 12, lg: 4, xs: 12}}>
                    <CustomButtonGroup
                        setPressedButtonInGroup={setPressedButton}
                        pressedButtonInGroup={pressedButton}
                        buttonName1={'Предстоящие'} buttonName2={'Прошедшие'}
                    />
                </Grid2>
            }

            {isMobile && (
                <MobileFilter
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    filters={filters}
                />
            )}

            {!isMobile && filters.map((filter, index) => {
                return <FilterItem
                    key={index}
                    filter={filter}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                />
            })}
        </Grid2>

    );
};

export default FilterSection;