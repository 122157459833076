import React, {Fragment, useEffect, useState} from "react";
import EventHeader from "../eventHeader/EventHeader";
import MainFooter from "../mainFooter/MainFooter";
import EventBody from "../eventBody/EventBody";
import {useParams} from "react-router-dom";
import jsonService from "../../services/jsonService";
import {Box, CircularProgress, Typography} from "@mui/material";

const EventPage = () => {
    const {hash} = useParams();
    const [eventData, setEventData] = useState(null);

    useEffect(() => {
        async function func() {
            setEventData(await jsonService.getEventsData(hash))
        }
        func();
        window.scrollTo(0, 0);
    }, [hash]);

    return (
        <Fragment>
            {!eventData ? <Box sx={{
                display: "grid",
                placeItems: "center",
                height: "100vh",
            }}>
                <CircularProgress />
            </Box> : <EventHeader eventData={eventData}/>}
            {!eventData ? <Box sx={{
                display: "grid",
                placeItems: "center",
                height: "100vh",
            }}>
                <CircularProgress />
            </Box> : Object.keys(eventData).length > 0 ?
                <EventBody eventData={eventData}/> :
                <Box sx={{
                    m: {xs: '64px auto', xl: "80px auto"},
                    flexGrow: 1,
                    maxWidth: {
                        xs: '100%',
                        lg: '944px',
                        xl: '1200px',
                    },
                    textAlign: "center",
                }}>
                    <Typography sx ={{
                        flexGrow: 1,
                        color: "#B6C1DD",
                        fontSize: {xs: '40px', sm: 'h5', xl: '50px'},
                    }}>
                        Страница не найдена
                    </Typography>
                </Box>
            }
            <MainFooter/>
        </Fragment>
    )
}

export default EventPage;