export function getGenitiveMonthName(date) {
    const formatter = new Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric'});
    const parts = formatter.formatToParts(date);
    return parts.find(part => part.type === 'month').value;
}

export function getDayAtStartOfDay(today) {
    return new Date(today.setHours(0, 0, 0, 0))
}

/* Добавляем месяцы к выбранной дате */
export const addMonths = (date, number) => {
    return new Date(date.setMonth(date.getMonth() + number));
}

/* Отнимаем месяцы у выбранной даты */
export const subtractMonths = (date, number) => {
    return new Date(date.setMonth(date.getMonth() - number));
}

export const getMonthYearString = (date) => {
    if (!date) return;
    return `${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getFullYear()}`
}

/* Если сегодня нет запланированных мероприятий, то показываем ближайшую дату с событиями  */
export const getClosestDateWithEvents = (events, monthYear) => {
    // Извлекаем месяц и год из строки
    const month = parseInt(monthYear.slice(0, 2), 10);
    const year = parseInt(monthYear.slice(2, 6), 10);

    const today = new Date(); // Получаем сегодняшнюю дату
    const isPastMonth = (year < today.getFullYear()) || (year === today.getFullYear() && month < today.getMonth() + 1);

    let resultDate = null;

    // Перебираем события
    events.forEach(event => {
        const eventDate = new Date(event.startdate * 1000); // Преобразуем timestamp в дату

        // Проверяем, совпадает ли месяц и год
        if (eventDate.getMonth() + 1 === month && eventDate.getFullYear() === year) {
            if (isPastMonth) {
                // Если месяц в прошлом, ищем самое раннее событие
                if (!resultDate || eventDate < resultDate) {
                    resultDate = eventDate;
                }
            } else {
                // Если месяц текущий или будущий, проверяем, что дата не раньше сегодняшнего дня
                if (eventDate >= today) {
                    if (!resultDate || eventDate < resultDate) {
                        resultDate = eventDate;
                    }
                }
            }
        }
    });

    // Если нашли событие, устанавливаем время на 00:00:00
    if (resultDate) {
        resultDate.setHours(0, 0, 0, 0); // Устанавливаем часы, минуты, секунды и миллисекунды
    }

    // Возвращаем найденную дату или null
    return resultDate ? resultDate : null;
}