import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import logo from "../../assets/images/logoblue.svg";
import logoMobile from "../../assets/images/logowhite.svg";
import './eventHeader.css';
import {Breadcrumbs, Button, Link, Container} from "@mui/material";
import imgDesktop from "../../assets/images/afisha2.jpg";
import imgTablet from "../../assets/images/afisha2.jpg";
import imgMobile from "../../assets/images/afisha3.jpg";
import jsonService from "../../services/jsonService";

const EventHeader = ({eventData}) => {
    const [bannerImg, setBannerImg] = useState(null);

    useEffect(() => {
        if (eventData.banner) {
            async function func() {
                const imgUrl = await jsonService.getSpeakerImage(eventData.banner);
                setBannerImg(imgUrl)
            }
            func();
        }
    }, []);

    /* Рендерим хлебные крошки страницы в зависимости от размера экрана */
    const redirectToRegistration = () => {
        return window.open(`${eventData.link}`, '_blank');
    }

    return (
        <Box>
            <AppBar position="static" sx={{
                display: {
                    xs: 'none',
                    xl: 'flex'
                },
                backgroundColor: 'white',
                boxShadow: 'none'
            }}>
                <Container disableGutters sx={{
                    maxWidth: {
                        xs: '100%',
                        lg: '944px',
                        xl: '1200px',
                    },
                    p: '24px 0',
                    m: '0 auto',
                    color: 'white',
                    height: '100%',
                }}>
                    <Link underline="hover" color="white" href="/" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fit-content',
                    }}>
                        <img
                            alt=''
                            className={'img-class-desktop'}
                            width={'98px'}
                            height={'35px'}
                            style={{cursor: 'pointer'}}
                            src={logo}/>
                        <Typography sx={{color: 'black', fontWeight: '500', fontSize: "15px"}} style={{cursor: 'pointer'}}>
                            Афиша мероприятий
                        </Typography>
                    </Link>
                </Container>
            </AppBar>
            {Object.keys(eventData).length > 0 ? <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: {
                    xl: '480px',
                    lg: '420px',
                    xs: '600px'
                },
                background: {
                    xl: `url(${bannerImg ? bannerImg : imgDesktop}) no-repeat`,
                    lg: `url(${bannerImg ? bannerImg : imgTablet}) no-repeat`,
                    xs: `url(${bannerImg ? bannerImg : imgMobile}) no-repeat`,
                },
                backgroundSize: {
                    xl: 'cover',
                    lg: 'cover',
                    xs: 'cover',
                },
                backgroundPosition: {
                    xl: 'center',
                    lg: '60%',
                    xs: 'bottom',
                },
            }}>
                <Container disableGutters sx={{
                    maxWidth: {
                        xs: '100%',
                        lg: '944px',
                        xl: '1200px',
                    },
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    alignItems: 'flex-start',
                    gap: '24px',
                    p: {
                        xl: '24px 0 48px',
                        lg: '24px 0 40px',
                        xs: '12px 20px 20px',
                    }
                }}>
                    <Box>
                        <Link underline="hover" href="/">
                            <Box sx={{
                                display: {
                                    xl: 'none',
                                    xs: 'flex'
                                },
                                alignItems: 'center',
                                mb: '60px'
                            }}>
                                <img
                                    alt=''
                                    className={'img-class-desktop'}
                                    width={'98px'}
                                    height={'35px'}
                                    src={logoMobile}/>
                                <Typography sx={{
                                    color: 'white',
                                    fontWeight: '500',
                                    fontSize: "15px",
                                    display: {
                                        lg: 'block',
                                        xs: 'none'
                                    },
                                }} style={{cursor: 'pointer'}}>
                                    Афиша мероприятий
                                </Typography>
                            </Box>
                        </Link>
                        <Breadcrumbs aria-label="breadcrumb" color={'white'} sx={{
                            display: {
                                xl: 'block',
                                xs: 'none'
                            },
                            fontSize: '12px',
                            lineHeight: '16px',
                            mb: '89px',
                        }}>
                            <Link underline="hover" color="white" href="/">
                                Афиша мероприятий
                            </Link>
                            <Typography sx={{opacity: '40%', fontSize: '12px'}} >
                                {eventData.topic}
                            </Typography>
                        </Breadcrumbs>
                        <Box sx={{
                            width: {
                                md: '50%',
                                xs: '100%',
                            },
                        }}>
                            <Typography sx={{
                                color: 'white',
                                fontSize: {
                                    xl: '50px',
                                    md: '40px',
                                    xs: '32px',
                                },
                                lineHeight: {
                                    xl: '50px',
                                    md: '40px',
                                    xs: '32px',
                                },
                                mb: {
                                    xl: '16px',
                                    md: '20px',
                                    xs: '12px',
                                },
                                letterSpacing: '-3px',
                            }}>
                                {eventData.topic}
                            </Typography>
                            <Typography sx={{
                                color: 'white',
                                fontSize: {
                                    md: '18px',
                                    xs: '16px',
                                },
                                lineHeight: {
                                    md: '22px',
                                    xs: '20px',
                                },
                                opacity: {
                                    xl: '.6',
                                },
                                WebkitLineClamp: '6',
                                WebkitBoxOrient: 'vertical',
                                display: '-webkit-box',
                                overflow: 'hidden',
                            }}>
                                {eventData.description}
                            </Typography>
                        </Box>
                    </Box>
                    {eventData.link ? <Button variant={'contained'} onClick={redirectToRegistration} sx={{
                        p: '16px 40px',
                        mt: {
                            lg: 'auto',
                            xs: '8px',
                        },
                        width: {
                            md: 'auto',
                            xs: '100%'
                        },
                        fontSize: '18px',
                        lineHeight: '20px',
                        backgroundColor: 'white',
                        color: '#0a2896',
                        borderRadius: '8px',
                        boxShadow: 'none',
                    }}>
                        {new Date(`${eventData.eventdate}:${eventData.formatendtime}`) >= new Date() ? 'Записаться' : 'Смотреть'}
                    </Button> : null}
                </Container>
            </Box> : null}
        </Box>
    )
};

export default EventHeader;