import {Box, Button, Grid2,} from "@mui/material";
import './eventsSection.css';
import {useEffect, useState} from "react";
import EventsDetails from "../eventsDetails/EventsDetails";
import {PRESSED_BUTTON} from "../customButtonGroup/CustomButtonGroup";
import NotFoundComponent from "../notFoundComponent/NotFoundComponent";

const EventsSection = ({selectedFilters, events, pressedButton, isMobile}) => {
    const [indexOfVisibleArray, setIndexOfVisibleArray] = useState(3);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [showMore, setShowMore] = useState(true);

    // Установка актуальных мероприятий в зависимости от выбора прошедшие/предстоящие
    useEffect(() => {
        const newIndexOfVisibleArray = 3;
        let newEvents = filterDataByCriteriaArray(events);
        newEvents = filterByBeforeAfter(newEvents);
        setFilteredEvents(newEvents);
        setIndexOfVisibleArray(newIndexOfVisibleArray);

        // Показываем кнопку если осталось показать более indexOfVisibleArray мероприятий
        setShowMore(newEvents.length > newIndexOfVisibleArray);
    }, [pressedButton]);

    // Установка актуальных мероприятий в зависимости от выбора фильтра
    useEffect(() => {
        let newEvents = filterDataByCriteriaArray(events);
        newEvents = filterByBeforeAfter(newEvents);
        setFilteredEvents(newEvents);

        // Показываем кнопку если осталось показать более indexOfVisibleArray мероприятий
        setShowMore(newEvents.length > indexOfVisibleArray);
    }, [selectedFilters]);

    // Получение текущего времени в Москве
    const getMoscowTime = () => {
        const moscowTime = new Date().toLocaleString('en-US', { timeZone: 'Europe/Moscow' });
        return new Date(moscowTime).getTime() / 1000;
    }

   /* Фильтр по критериям */
    const filterDataByCriteriaArray = (data) => {
        return data.filter(event => {
            return selectedFilters.every(criterion => {
                const eventFilter = event.filters[criterion.filterId];

                if (eventFilter) {
                    return eventFilter.includes(criterion.optionId) || criterion.optionId === -1;
                }
                return false;
            });
        });
    };

    /* Фильтр для кнопок "предстоящие" и "прошедшие" */
    const filterByBeforeAfter = (data) => {
        if (pressedButton === PRESSED_BUTTON.NONE) {
            return data;
        }
    
        const currentTime = getMoscowTime();
        // Сортировка на "предстоящие" или "прошедшие" события
        const result = data.filter(event => {
            if (pressedButton === PRESSED_BUTTON.AFTER) {
                return event.startdate > currentTime;
            } else if ((pressedButton === PRESSED_BUTTON.BEFORE)) {
                return event.startdate < currentTime;
            }
        });

        // Сортировка по часам от ранней к поздней дате для "предстоящих" событий
        result.sort((a, b) => a.startdate - b.startdate);
        // Сортировка по часам от поздней к ранней дате для "прошедших" событий
        if (pressedButton === PRESSED_BUTTON.BEFORE) {
            result.reverse();
        }

        return result;
    }

    /* Логика для кнопки "Показать еще" */
    const handleClick = () => {
        const newIndex = indexOfVisibleArray + 3;
        setIndexOfVisibleArray(newIndex);
        setShowMore(filteredEvents.length > newIndex);
    }

    return (
        <Box id={'eventsSection'}>
            {filteredEvents.slice(0, indexOfVisibleArray).map((d, index) => {
                const day = d.formatmonth.split(' ')[0];
                const month = d.formatmonth.split(' ')[1];
                return <EventsDetails
                    isMobile={isMobile}
                    key={index}
                    data={{
                        formatdate: d.formatdate,
                        formatendtime: d.formatendtime,
                        cityName: d.cityname,
                        link: d.link,
                        hash: d.hash,
                        description: d.intro,
                        time: d.formattime,
                        name: d.name,
                        date: day,
                        month: month,
                        chip: d.typename,
                        image: d.firstspeaker.avatar,
                        speakerName: d.firstspeaker.name,
                        speakerPosition: d.firstspeaker.description,
                        speakerExists: Object.keys(d.firstspeaker).length !== 0
                    }}
                />
            })}

            {filteredEvents.length === 0 && <NotFoundComponent text={'Мероприятий не найдено'} />}
            {filteredEvents.length === 0 && <Grid2 width={'100%'} size={12}></Grid2>}

            {/* Кнопка не показана, если активен фильтр месяца и все данные из этого фильтра показаны */}
            {showMore &&
                <Button
                    sx={{
                        height: '52px',
                        backgroundColor: '#f3f7fa',
                        color: '#606981',
                        borderRadius: '8px',
                        mb: 4,
                    }}
                    variant={'contained'}
                    fullWidth
                    onClick={handleClick}
                >
                    Показать еще
                </Button>
            }
        </Box>
    )
}

export default EventsSection;