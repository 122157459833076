export const getData = async (url) => {
    return fetch(url, {
        method: 'GET',
        cache: 'no-store',
    })
        .then(response => {
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let result = '';

            return new Promise((resolve, reject) => {
                function read() {
                    reader.read().then(({ done, value }) => {
                        if (done) {
                            resolve(result);  // Resolve the final result
                            return;
                        }
                        // Decode the chunk and append to the result
                        result += decoder.decode(value, { stream: true });
                        read();  // Continue reading the next chunk
                    }).catch(error => reject(error));
                }

                read();
            });
        })
        .catch(error => {
            console.error('Error fetching the data:', error);
        });
};

export const getBlobData = async (url) => {
    const response = await fetch(url);

    if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    const blob = await response.blob();

    return URL.createObjectURL(blob);
}

export const artificialPause = async (milliSeconds) => {
    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    await delay(milliSeconds);
}
