import {Box, Typography, Pagination} from "@mui/material";
import {Link, useNavigate} from 'react-router-dom';
import {getGenitiveMonthName} from "../../utils/dateUtils";
import {Fragment, useEffect, useState} from "react";
import CalendarEventDetails from "../calendarEventDetails/CalendarEventDetails";

const CalendarEvents = ({selectedDay, filteredData}) => {
    const [page, setPage] = useState(1);
    const rowsPerPage = 2;
    const navigate = useNavigate();

    useEffect(() => {
        setPage(1);
    }, [selectedDay]);

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    return <Fragment>
        <Box variant={'h5'} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '16px'}}>
            <Typography color={'#2f3441'} sx={{fontSize: '32px', lineHeight: '40px'}}>
                {selectedDay && `${selectedDay.getDate()} ${getGenitiveMonthName(selectedDay)}`}
            </Typography>
            <Pagination
                count={Math.ceil(filteredData.length / rowsPerPage)}
                page={page}
                onChange={handlePageChange}
                sx={{
                    '.MuiPaginationItem-root': {
                        display: 'none',
                    },
                    '.MuiPaginationItem-previousNext': {
                        display: 'flex',
                        borderRadius: '50%',
                        border: '1px solid #0A2896',
                        color: '#0A2896',
                    },
                }}
            />
        </Box>
        <Box>
            {filteredData
                .sort((a, b) => {
                    if (a.formattime > b.formattime) {
                        return 1;
                    }
                    if (a.formattime < b.formattime) {
                        return -1;
                    }
                    return 0;
                })
                .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                .map((d, index) => (
                    <Link key={index} to={`/event/${d.hash}`} replace={true} className={'calendar-event-content'} onClick={() => navigate(`/event/${d.hash}`)} style={{textDecoration: 'none', display: 'block', color: '#2f3441'}}>
                        <CalendarEventDetails
                            key={index}
                            name={d.name}
                            time={d.formattime}
                            location={d.cityname}
                            typename={d.typename}
                        />
                    </Link>
                ))
            }
        </Box>
    </Fragment>
}

export default CalendarEvents;