import React, {useState} from 'react';
import {Box, Button, ButtonGroup} from '@mui/material';

export const PRESSED_BUTTON = {
    BEFORE: 'before',
    AFTER: 'after',
    NONE: null
}

const buttonStyles = {
    pressed: {
        backgroundColor: 'white',
        color: '#0a2896',
        borderColor: '#0a2896',
        border: '2px solid',
        borderRadius: '8px',
    },
    released: {
        backgroundColor: 'inherit',
        color: '#606981',
        borderColor: 'inherit',
        border: 'none',
        borderRadius: '8px',
    }
};
const CustomButtonGroup = ({setPressedButtonInGroup, pressedButtonInGroup, buttonName1, buttonName2}) => {
    const [pressedButton, setPressedButton] = useState(1);

    /*Выбираем мероприятия в зависимости от кнопки (прошедшие или предстоящие)*/
    const handleButtonClick = (number, beforeAfter) => {
        setPressedButtonInGroup(beforeAfter)
        setPressedButton(number);
    };

    return (
        <Box>
            <ButtonGroup variant="contained" aria-label="color button group" fullWidth
                         sx={{height: '60px', borderRadius: '8px', boxShadow: 'none', border: '1px solid #D4D7DF'}}>
                <Button
                        sx ={{textTransform: 'none', fontSize: '18px', boxShadow: 'none'}}
                        fullWidth
                        style={pressedButton === 2 ? buttonStyles.pressed : buttonStyles.released}
                        onClick={() => {
                            handleButtonClick(2, PRESSED_BUTTON.BEFORE)
                        }}
                >
                    {buttonName2}
                </Button>
                <Button
                    sx ={{textTransform: 'none', fontSize: '18px'}}
                    style={pressedButton === 1 ? buttonStyles.pressed : buttonStyles.released}
                    fullWidth
                    onClick={() => {
                        handleButtonClick(1, PRESSED_BUTTON.AFTER)
                    }}
                >
                    {buttonName1}
                </Button>
            </ButtonGroup>
        </Box>
    );
};

export default CustomButtonGroup;
