import {Container, Typography, Box} from "@mui/material";
import './mainFooter.css';
import img from "../../assets/images/logoblue.svg";

const MainFooter = () => {
    return (
        <Box sx={{
            backgroundColor: '#FFFFFF',
        }}>
            <Container disableGutters sx={{
                p: '24px',
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: {
                    xs: '100%',
                    lg: '944px',
                    xl: '1200px',
                },
            }}>
                <img alt='' className={'img-class__footer'} src={img}/>

                <Typography
                    className={'footer-copyright'} color={'#2f3441'}>
                    © ВТБ, 2024
                </Typography>
            </Container>
        </Box>
    )
}

export default MainFooter;