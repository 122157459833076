import {Box, Grid2, MenuItem, Select, Typography} from "@mui/material";

export const FilterItem = ({filter, selectedFilters, setSelectedFilters}) => {
    const handleChange = (e) => {
        const optionId = e.target.value;
        const filterId = filter.id;

        // Создаем новый Map на основе текущих выбранных фильтров
        const newSelectedFiltersMap = new Map(selectedFilters.map(item => [item.filterId, item]))
        if (optionId === -1) {
            // Удаляем фильтр
            newSelectedFiltersMap.delete(filterId);
        } else {
            // Обновляем или добавляем фильтр
            newSelectedFiltersMap.set(filterId, {filterId, optionId});
        }
        // Преобразуем Map обратно в массив
        const newSelectedFilters = Array.from(newSelectedFiltersMap.values());

        setSelectedFilters(newSelectedFilters);
    }

    /* Отображение дат в селекторе */
    const sortByCustomOrder = (data) => {
        const getLastTwoDigits = (id) => id % 100;
      
        return data.sort((a, b) => {
          const lastDigitA = getLastTwoDigits(a.id);
          const lastDigitB = getLastTwoDigits(b.id);
    
          if (lastDigitA !== lastDigitB) {
            return lastDigitA - lastDigitB;
          }

          return a.id - b.id;
        });
    };

    const filterOptions = filter.get_data === 1 ? sortByCustomOrder(Object.values(filter.options)) : Object.values(filter.options);
    
    return <Grid2 size={{xl:12, xs:12, md: 2.4}}
           sx={{
               height: 74,
               borderRadius: '8px',
               display: "flex",
               flexDirection: "column",
               justifyContent: "space-between",
               backgroundColor: 'white',
               marginRight:{xl: 0}
           }}
    >
        <Box sx={{
            p: '16px 20px',
            position: 'relative',
        }}>
            <Typography variant="subtitle1" noWrap>
                <Box className={'filter-name-text'} sx={{
                    overflowX: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '10px',
                }}>
                    {filter.name}
                </Box>
            </Typography>
            <Select
                value={selectedFilters.find(item => item.filterId === filter.id)?.optionId || -1}
                defaultValue={-1}
                displayEmpty
                variant="standard"
                disableUnderline
                onChange={handleChange}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200, // Set max height for the menu
                            overflowY: 'auto', // Enable vertical scrolling
                        },
                    },
                }}
                IconComponent={() => (
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position: 'absolute', top: '24px', right: '20px'}}>
                        <path
                            d="M16.0749 9.00002L12.1949 12.88L8.31493 9.00002C7.92493 8.61002 7.29493 8.61002 6.90493 9.00002C6.51493 9.39002 6.51493 10.02 6.90493 10.41L11.4949 15C11.8849 15.39 12.5149 15.39 12.9049 15L17.4949 10.41C17.8849 10.02 17.8849 9.39002 17.4949 9.00002C17.1049 8.62002 16.4649 8.61002 16.0749 9.00002Z"
                            fill="#606981"/>
                    </svg>
                )}
                sx={{
                    width: '100%',
                    color: '#2f3441',
                    position: 'static',
                    '& .MuiSelect-select::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        zIndex: 1,
                    },
                }}
            >
                <MenuItem selected value={-1}
                          sx={{color: '#2f3441'}}>
                    Не выбрано
                </MenuItem>
                {filterOptions.map(option => (
                    <MenuItem key={option.id} value={option.id}
                              sx={{
                                  whiteSpace: 'normal',          // Allow wrapping to new lines
                                  overflowWrap: 'break-word',    // Break text when necessary
                                  wordBreak: 'break-word',       // Break long words
                                  maxWidth: '100%',              // Ensure it doesn't exceed Select width
                                  width: '100%',              // Ensure it doesn't exceed Select width
                                  color: '#2f3441',
                              }}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    </Grid2>
}